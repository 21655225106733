<template>
  <div class="ud-body">
    <a-card :bordered="true">
      <a-form
        ref="form"
        :rules="rules"
        :label-col="labelCol"
        :model="form"
        :wrapper-col="wrapperCol"
        class="form"
      >
        <a-row>
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="调拨单号" name=" allotCode">
              <a-input
                placeholder="请输入调拨单号"
                v-model:value="form.allotCode"
              ></a-input> </a-form-item
          ></a-col>
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="电池编号" name="batteryCode">
              <a-input
                placeholder="请输入电池编号"
                v-model:value="form.batteryCode"
              ></a-input> </a-form-item
          ></a-col>
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="调出方" name="out">
              <a-select v-model:value="form.out" placeholder="请选择调出方">
                <a-select-option value="0"
                  >厦门远丞科技有限公司</a-select-option
                >
              </a-select>
            </a-form-item></a-col
          >
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="调入方" name="join">
              <a-select v-model:value="form.join" placeholder="请选择调入方">
                <a-select-option value="0"
                  >厦门远丞科技有限公司</a-select-option
                >
              </a-select>
            </a-form-item></a-col
          >
        </a-row>
        <a-row v-if="expand">
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="调拨单状态" name="allotState">
              <a-select
                v-model:value="form.allotState"
                placeholder="请选择调拨单状态"
              >
                <a-select-option value="0">创建</a-select-option>
                <a-select-option value="1">已调出确认</a-select-option>
                <a-select-option value="2">已调入确认</a-select-option>
                <a-select-option value="3">作废</a-select-option>
              </a-select>
            </a-form-item></a-col
          >
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="调出站点" name="outPoint">
              <a-select
                v-model:value="form.outPoint"
                placeholder="请选择调出站点"
              >
              </a-select> </a-form-item
          ></a-col>
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="调入站点" name="joinPoint">
              <a-select
                v-model:value="form.joinPoint"
                placeholder="请选择调入站点"
              >
              </a-select> </a-form-item
          ></a-col>
        </a-row>
        <a-row type="flex" justify="end">
          <a-space class="btn-wrap">
            <a-button type="primary" @click="reload"
              ><i class="iconfont icon-zaitu"></i>查询</a-button
            >
            <a-button @click="reset">
              <i class="iconfont icon-mimazhongzhi"></i>重置</a-button
            >
            <span
              v-if="!expand"
              @click="expandForm"
              style="color: #1890ff; cursor: pointer"
            >
              展开<i class="iconfont icon-xiangxiajiantou"></i>
            </span>
            <span
              v-else
              @click="expandForm"
              style="color: #1890ff; cursor: pointer"
            >
              收起<i class="iconfont icon-xiangshang_jiantou"></i>
            </span>
          </a-space>
        </a-row>
      </a-form>
    </a-card>
    <a-card :bordered="true" class="table">
      <div>
        <ud-pro-table
          ref="table"
          :rowKey="(record) => record.allotCode"
          :columns="columns"
          :datasource="data"
          @change="handleChange"
          :scroll="{ x: 'max-content' }"
        >
          <template #allotCode="{ record }">
            <div style="color: #409eff; text-decoration: underline">
              {{ record.allotCode }}
            </div>
          </template>
          <template #toolbar>
            <a-space style="margin: 0 20px">
              <a-button type="primary" @click="openEdit()">
                <template #icon>
                  <plus-outlined />
                </template>
                <span>新建</span>
              </a-button>
            </a-space>
          </template>
          <template #action="{ record }">
            <a-space>
              <a @click="openDetail(record)">详情</a>
              <a @click="openEdit(record)">修改</a>
              <a-divider type="vertical" />
              <a-popconfirm title="确定要删除此项目吗？">
                <a class="ud-text-danger">删除</a>
              </a-popconfirm>
              <a-divider type="vertical" />
              <a-dropdown>
                <a class="ant-dropdown-link" @click.prevent>
                  更多
                  <DownOutlined />
                </a>
                <template #overlay>
                  <a-menu>
                    <a-menu-item key="log" @click="checkLog(record)"
                      >操作日志</a-menu-item
                    >
                  </a-menu>
                </template>
              </a-dropdown>
            </a-space>
          </template>
          <template #customOutTitle>
            <div>
              调出方
              <i
                class="iconfont icon-bukejian"
                style="cursor: pointer"
                v-if="!isShowOut"
                @click="isShowOut = true"
              ></i>
              <i
                class="iconfont icon-kejian"
                style="cursor: pointer"
                v-else
                @click="isShowOut = false"
              ></i>
            </div>
          </template>
          <template #outCustom="{ record }">
            <div v-if="!isShowOut">
              {{
                record.out.substring(0, 1) +
                new Array(record.out.length).join('*')
              }}
            </div>
            <div v-else>
              {{ record.out }}
            </div>
          </template>
          <template #customJoinTitle>
            <div>
              调入方
              <i
                class="iconfont icon-bukejian"
                style="cursor: pointer"
                v-if="!isShowJoin"
                @click="isShowJoin = true"
              ></i>
              <i
                class="iconfont icon-kejian"
                style="cursor: pointer"
                v-else
                @click="isShowJoin = false"
              ></i>
            </div>
          </template>
          <template #joinCustom="{ record }">
            <div v-if="!isShowJoin">
              {{
                record.join.substring(0, 1) +
                new Array(record.join.length).join('*')
              }}
            </div>
            <div v-else>
              {{ record.join }}
            </div>
          </template>
        </ud-pro-table>
      </div>
      <a-modal
        v-model:visible="visibleLog"
        title="操作日志"
        @ok="handleLogOk"
        centered
        :width="800"
        :footer="null"
      >
        <a-table
          row-key="actionTime"
          :data-source="logData"
          :columns="logColumns"
          bordered
          :scroll="{ y: 500 }"
          :pagination="pagination"
        >
        </a-table>
      </a-modal>
      <location-detail v-model:visible="showDetail" :data="current || {}" />
      <location-edit
        v-model:visible="showEdit"
        :data="current"
        @done="reload"
      />
    </a-card>
  </div>
</template>

<script>
import locationDetail from './location-detail.vue'
import locationEdit from './location-edit.vue'
import { PlusOutlined, DownOutlined } from '@ant-design/icons-vue'
export default {
  components: {
    // DownOutlined,
    PlusOutlined,
    DownOutlined,
    locationEdit,
    locationDetail
    // ExclamationCircleOutlined
    // operationDetail,
    // operationEdit
  },
  data() {
    return {
      visibleLog: false,
      logData: [
        {
          actionContent: '确认调出',
          actionPerson: 'guowenjun',
          actionTime: '2022-01-12 15:46:38'
        },
        {
          actionContent: '确认调入',
          actionPerson: 'guowenjun',
          actionTime: '2022-01-12 15:46:38'
        },
        {
          actionContent: '创建',
          actionPerson: 'guowenjun',
          actionTime: '2022-01-12 15:46:38'
        }
      ],
      logColumns: [
        {
          title: '序号',
          dataIndex: 'index',
          width: 48,
          // align: 'center',
          customRender: ({ text, record, index }) => index + 1
        },
        {
          title: '操作内容',
          // align: 'center',
          dataIndex: 'actionContent'
        },
        {
          title: '操作人',
          // align: 'center',
          dataIndex: 'actionPerson'
        },
        {
          title: '操作时间',
          align: 'center',
          width: 200,
          dataIndex: 'actionTime'
        }
      ],
      labelCol: { md: { span: 6 }, sm: { span: 24 } },
      wrapperCol: { md: { span: 16 }, sm: { span: 24 } },
      showDetail: false,
      showEdit: false,
      current: null,
      form: {},
      rules: {},
      expand: false,
      isShowOut: false,
      isShowJoin: false,
      columns: [
        {
          title: '序号',
          width: 48,
          // align: 'center',
          customRender: ({ text, record, index }) => index + 1
        },
        {
          title: '调拨单号',
          // width: 170,
          // align: 'center',
          dataIndex: 'allotCode',
          slots: {
            customRender: 'allotCode'
          }
        },
        {
          title: '调拨单状态',
          // width: 85,
          // align: 'center',
          dataIndex: 'allotState'
        },
        {
          // width: 175,
          // align: 'center',
          dataIndex: 'out',
          slots: {
            title: 'customOutTitle',
            customRender: 'outCustom'
          }
        },
        {
          // width: 175,
          // align: 'center',
          dataIndex: 'thawMoney',
          slots: {
            title: 'customJoinTitle',
            customRender: 'joinCustom'
          }
        },
        {
          title: '调出站点',
          // width: 175,
          // align: 'center',
          dataIndex: 'outPoint'
        },
        {
          title: '调入站点',
          // width: 175,
          // align: 'center',
          dataIndex: 'joinPoint'
        },
        {
          title: '电池编号',
          // width: 175,
          // align: 'center',
          dataIndex: 'batteryCode'
        },
        {
          title: '创建时间',
          // width: 175,
          // align: 'center',
          dataIndex: 'createTime'
        },
        {
          title: '操作',
          width: 200,
          dataIndex: 'action',
          align: 'center',
          slots: {
            customRender: 'action'
          }
        }
      ],
      data: [
        {
          allotCode: 'DB2109171505132',
          allotState: '已确认调入',
          out: '厦门远丞科技有限公司',
          join: '厦门远丞科技有限公司',
          outPoint: '明发测试站点',
          joinPoint: '明发2',
          batteryCode: 'BT07203012YDNO21095398',
          createTime: '2022-01-11 09:45:36'
        },
        {
          allotCode: 'DB2109171505132',
          allotState: '已确认调入',
          out: '厦门远丞科技有限公司',
          join: '厦门远丞科技有限公司',
          outPoint: '明发测试站点',
          joinPoint: '明发2',
          batteryCode: 'BT07203012YDNO21095398',
          createTime: '2022-01-11 09:45:36'
        }
      ],
      customerForm: {}
    }
  },
  methods: {
    // 查看操作日志
    checkLog(record) {
      this.visibleLog = true
      this.customerForm.allotCode = record.allotCode
    },
    openDetail(row) {
      this.current = row
      this.showDetail = true
    },
    openEdit(row) {
      console.log(row)
      this.current = row
      this.showEdit = true
    },
    // 新增计费模板确定按钮回调事件
    handleBillingOk() {
      this.$refs.billingForm.validate().then((res) => {
        console.log(res)
        this.$message.success('添加计费模板成功！')
        this.visible = false
      })
    },
    // 是否展开表单
    expandForm() {
      this.expand = !this.expand
    },
    // 表格改变
    handleChange() {}
  }
}
</script>
<style lang='less' scoped>
.form {
  .btn-wrap {
    padding-right: 20px;
    .iconfont {
      margin-right: 5px;
    }
  }
}
.table {
  margin: 10px 0;
}
</style>
